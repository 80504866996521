import React from 'react';
import styles from './footer.module.css';

const footer = () => {
    return ( 
        <footer className={styles.footer} >
        <div className="footer-copyright text-center py-3">© The Fountain of Life Church
        </div>
      </footer>
     );
}
 
export default footer;