import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Spinners from '../../../Partials/Spinner/Spinner';
import axiosInstance from '../../../Helpers/Axios/axiosInstance';
import axios from 'axios';
import Aux from '../../../HOC/Aux';
import Pscard from '../../../Partials/paymentlogo/paystacklogo';
import * as knost from '../../../Helpers/Constants/Konst'; 

class ForeignSummary  extends Component {


    state = {
        myForm:{
            fullname:  null,
            mobile:    null,
            email:     null,
            currency:  null,
            purpose:   null,
            amount:    null,


                formErrors:{
                    fullname: 'e',
                    mobile: 'e',
                    email:'e',
                    address:'e',    
                    currency:  'e',
                    purpose:   'e',
                    amount:    'e',
                }
        },
        loading:false,
    
    }

    
    componentDidMount(){
        const propState = this.props.location.mystate;
        if(propState){
            this.setState({ myForm : propState});
        }
        
    }


    submitFormHandler = ()=>{
     this.setState({loading:true});
     const myForm = this.state.myForm;

     const info = {
        fullname:  myForm.fullname,
        mobile:    myForm.mobile,
        email:     myForm.email,
        currency:  'USD',
        purpose:   myForm.purpose,
        amount:    myForm.amount,
     }
  //  console.log(info);

    const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
    const source = CancelToken.source();
     axiosInstance.post('/dollar-payment',info,{
        cancelToken: source.token
     }).then(resp =>{
        //this.setState({loading:false});
        //console.log(resp.data.url);
        //this.props.history.replace('/foreign-redirect');
        window.location.href = resp.data.url
     }).catch(e=> { 
         this.setState({loading:false}); 
         console.log(e)});

    }




    render(){
          const {myForm,loading}  = this.state;
          let myTable = (
           
                    <div className="alert alert-info" role="alert">
                    <h4 className="alert-heading">No data available!</h4>
                    <p className="mb-0">
                    <Link to="/" className="btn btn-light mr-3">ANOTHER PAYMENT</Link>
                    <a href={knost.TFOLCHOME} className="btn btn-success">TFOLC HOME</a>
                    </p>
                 </div>
          )
            

          if(myForm.fullname !== null ){
            myTable = ( 
                <Aux>
                
                <table className="table table-sm table-borderless">
                <thead>
                    <tr>
                        <th scope="col" style={{textTransform:"uppercase"}}>{myForm.fullname } </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td >{myForm.mobile}</td>
                    </tr>
                    <tr>
                        <td >{myForm.email}</td>
                   </tr>
                   <tr>
                        <td >Payment for <strong>{myForm.purpose}</strong></td>
                    </tr>    
                   <tr>
                        <th >$ {myForm.amount}</th>
                   </tr>              
            </tbody>
            </table>
            <div className="card-footer bg-transparent ">
                <Link to="/foreign-payment" className="btn btn-danger mr-3">Go Back</Link>
                {myForm.fullname=== null ? null : <button onClick={this.submitFormHandler} className="btn btn-success">Continue</button>}
            </div>
            </Aux>
            )
          }








        return (
            <Aux>
            <div className="card ">
            <div className="card-header text-white bg-danger">
                Transaction Summary
               </div>
               <div className="card-body">
            { loading ? <Spinners /> : myTable}
            </div> 
            </div> 
            <Pscard />
            </Aux>
          );

    


}



}
 
export default ForeignSummary;