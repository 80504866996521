import React, { Component } from 'react';
import Accounts from "../../components/accounts/accounts";
import Pounds from "../../components/accounts/pounds";
import Aux from '../../HOC/Aux';

class Landing extends Component {

    state={
        showAccounts: false,
        showPoundsAccount: false
    }

    showAccountsHandler = () =>{
        this.setState({showAccounts:!this.state.showAccounts});
    }

    showPoundsAccountHandler = () =>{
        this.setState({showPoundsAccount:!this.state.showPoundsAccount});
    }


    ForeignPaymentHandler =() =>{
        this.props.history.push('/foreign-payment');
    }

    LocalPaymentHandler =() =>{
        this.props.history.push('/local-payment');
    }

    render(){

        let acc, pacc = null;
        if(this.state.showAccounts){
            acc=(<Accounts/>);
        }


        if(this.state.showPoundsAccount){
            pacc=(<Pounds/>);
        }


    

    return(

        <Aux>
            {/* <div className="row">
            <div className="col-sm-12 mb-3">
            <button type="button" onClick={this.showAccountsHandler} className="btn btn-danger btn-lg btn-block">
            BANK TRANSFER (OFFLINE)
            </button>
            </div>
            { acc}
            </div> */}
            
            {/* <div className="row">
            <div className="col-sm-12 mb-3">
            <a href='https://flutterwave.com/pay/tfolcinternationalgiving' target='_blank' rel="noopener noreferrer" className="btn btn-primary btn-lg btn-block">
            INTERNATIONAL GIVING
            </a>
            </div>
            </div> */}

            <div className="row">
            <div className="col-sm-12 mb-3">
            <a href='https://paystack.com/pay/tfolcofferingnaira' target='_blank' rel="noopener noreferrer" className="btn btn-primary btn-lg btn-block">
            LOCAL
            </a>
            </div>
            </div>    
                
            <div className="row">
            <div className="col-sm-12 mb-3">
            <a href='https://paystack.com/pay/tfolcofferingusd' target='_blank' rel="noopener noreferrer" className="btn btn-success btn-lg btn-block">
            INTERNATIONAL
            </a>
            </div>
            </div>

            {/* <div className="row">
            <div className="col-sm-12 mb-3">
            <a href='https://flutterwave.com/pay/tfolcinternationalgiving' target='_blank' rel="noopener noreferrer" className="btn btn-danger btn-lg btn-block">
            INTERNATIONAL
            </a>
            </div>
            </div> */}


            {/* <div className="row">
            <div className="col-sm-12 mb-3">
            <button type="button" onClick={this.showPoundsAccountHandler} className="btn btn-danger btn-lg btn-block">
            POUNDS
            </button>
            </div>
            { pacc}
            </div> */}


            <div className="alert alert-info text-center">
            <p><em>"* All payment transactions are accepted regardless of the currency. *"</em></p>
            </div>
            
            </Aux>   
        )
    }
}

export default Landing;