import React from 'react';
import ps from '../../assets/images/paystack.png';


const globalcard = () => {
    return ( 
        <div className="col-12-md mt-5  img-fluid">
        <img src={ps} alt="..." height="70px" />
        </div>
     );
}
 
export default globalcard;