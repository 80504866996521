import React from 'react';
import {NavLink} from 'react-router-dom';

const nav = () => {
    return (  
<nav className="navbar navbar-expand-lg  navbar-dark bg-danger my-3">
  <NavLink className="navbar-brand" to="">Online Payment</NavLink>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarNav">
    <ul className="navbar-nav">
      <li className="nav-item ">
      <NavLink exact className="nav-link" activeClassName="active" to="/foreign-payment"> Dollar ($)<span className="sr-only">(current)</span></NavLink>
      </li>
      <li className="nav-item">
      <NavLink  exact to="/local-payment"   activeClassName="active" className="nav-link" >Naira (<span>&#8358;</span>)</NavLink>
      </li>
      </ul>
      <ul className="navbar-nav ml-auto">
      <li className="nav-item my-lg-0">
      <a  href="http://tfolc.org/"  className="nav-link" >TFOLC Home</a>
      </li>  
    </ul>
  </div>
</nav>
    );
}
 
export default nav;