




        export const TFOLCHOME = "https://www.tfolc.org"


      /* 
      ***** GLOBALPAY SETTINGS
      */
      
      // LIVE
       export const POSTURL ="https://www.globalpay.com.ng/GlobalPayAPI/Paymentgatewaycapture.aspx";
       export const MERCHANTID = 378; // live
        //DEMO
        // export const POSTURL ="https://demo.globalpay.com.ng/GlobalPayAPI/Paymentgatewaycapture.aspx";
        // export const MERCHANTID = 5580; 



 
      /* 
      ***** PAYSTACK SETTINGS
      */       