
import React from 'react';
import Aux from '../../HOC/Aux';
import Nav from '../nav/nav';
import LocalForm from '../../containers/LocalForm/LocalForm';


const local = () => {


    return ( 
        <Aux>
            <Nav/> 
               <LocalForm/>
        </Aux>
  

     );
}
 
export default local;