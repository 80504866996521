





const emailRegx = RegExp(/^\w+([-._]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);

class StandardForm {
    

    constructor(type,css){
        this.type = type;
        this.css = css;
     
    }
    // set success css or write null
    successBorderCss = "is-valid";
    successFieldCss = "valid-feedback";
    successValue ="looks good!";

    myname = "ademola"; 


   


     isformValid = (formErrors,formValue) =>{
        let Valid = true;
        // check for error fields
        Object.values(formErrors).forEach( elem =>{
           elem.length > 1 && (Valid = false); // i made this one error length > 1 because i set the error to have at least 1 check formErrors
          // console.log(elem.length);
        });
         // check form value fields
        Object.values(formValue).forEach( elem =>{
            elem === null  && (Valid = false);
         });
        // console.log(Valid);
        return Valid;

    }


    alert = (value) =>
    { 
        // values is the form errors 
        let  arr = { css: null, value:null };
        if(value.length > 1 &&  this.type === 'borderError'){
            arr.css = this.css; // danger css
        }else if(value.length < 1 &&  this.type === 'borderError'){
            arr.css = this.successBorderCss; //success css
        }else if(value.length > 1 &&  this.type === 'alertError'){
            arr.css = this.css;
            arr.value = value;
        }else if(value.length < 1 &&  this.type === 'alertError'){
            arr.css = this.successFieldCss;
            arr.value = this.successValue; // success value
        }
        return arr
    }
    

    validation =(name,value,formErrsState,formValueState)=>{
        // const {name,value} = event.target;
        // let formErrsState = this.state.myForm.formErrors;
        // let formValueState = this.state.myForm;
        const arr = {
            errorState: null,
            valueState:null
        };

        switch (name) {
            case 'fullname':
            formErrsState.fullname = value.length < 5 ? 
            "Fullname must be more than of 8 characters" :""
            formValueState.fullname = value
            break;
            case 'mobile':
            formErrsState.mobile = value.length < 6 || isNaN(value) ?
             "Please provide a valid Mobile number" :""
            formValueState.mobile = value
            break;
            case 'amount':
            formErrsState.amount = value.length < 1 || isNaN(value) ?
             "Please provide a valid Amount" :""
            formValueState.amount = value
            break;
            case 'email':
            formErrsState.email = value.length > 0 && emailRegx.test(value) ? 
            "" :"Must enter a valid email address"
            formValueState.email = value
            break;
            case 'currency':
            formErrsState.currency = value.length < 3 ? 
            "Please provide a valid currency" :""
            formValueState.currency = value
            break;
            case 'purpose':
            formErrsState.purpose = value.length < 3 ? 
            "Please select a valid purpose" :""
            formValueState.purpose = value
            break;
            case 'address':
            formErrsState.address = value.length < 5 ? 
            "minimum name of 6 character" :""
            formValueState.address = value
            break;        
            default:
            break;
        }
             arr.errorState = formErrsState;
             arr.valueState = formValueState;
        return arr;
    }








    

}


export default StandardForm;