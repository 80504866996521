import React, { Component } from 'react';
import formValidation from '../../Helpers/FormValidation/StandardForm';
import Aux from '../../HOC/Aux';
import {withRouter} from 'react-router-dom';
import Pscard from '../../Partials/paymentlogo/paystacklogo';






class ForeignForm extends Component{

    state = {
        myForm:{
            fullname:  null,
            mobile:    null,
            email:     null,
            purpose:   null,
            amount:    null,


                formErrors:{
                    fullname: 'e',
                    mobile: 'e',
                    email:'e',
                    address:'e',    
                    purpose:   'e',
                    amount:    'e',
                }
        },
        loading:false,
        success: null,
        error : null,
        display:false, // for hiding alert
        show: false ,
    
    }



    


    changeHandler = (event) =>{
       
        const {name, value} = event.target;
        //console.log(name + ' '+ value);
        let formErrs = this.state.myForm.formErrors;
        let formValue = this.state.myForm;
        // validation
        const v = new formValidation('borderError','is-valid');
        const {errorState,valueState} = v.validation(name,value,formErrs,formValue);

        //set state
        let  updateForm = {...this.state.myForm};
        updateForm.formErrors = errorState;
        updateForm = valueState;
        this.setState({myForm:updateForm});
    }


    submitFormHandler=(event)=>{
        event.preventDefault();
        // console.log(21);
        const {formErrors} = this.state.myForm;
        const v = new formValidation('borderError','is-valid');
        if( v.isformValid(formErrors,this.state.myForm)){
            this.props.history.push({
                pathname:'/foreign-summary',
                mystate: this.state.myForm
            });
        }else{
            //error
            console.log("many errors");
        }




    }


    

    render(){

        const formErz = this.state.myForm.formErrors;
        // alert
        const borderAlert = new formValidation('borderError','is-invalid');
        const fieldAlert = new formValidation('alertError',"invalid-feedback");
        
        let theForm = (
              <form noValidate>
                <div className="form-row">
                    <div className="col-md-5 mb-3">
                    <label >Full name</label>
                    <input type="text" onChange={this.changeHandler} className={['form-control', borderAlert.alert(formErz.fullname).css  ].join(' ')}
                       name="fullname"/>
                    <div className={fieldAlert.alert(formErz.fullname).css}>
                    {fieldAlert.alert(formErz.fullname).value}
                    </div>
                    </div>

                    <div className="col-md-4 mb-3">
                    <label >Email</label>
                    <input type="email" onChange={this.changeHandler} className={['form-control', borderAlert.alert(formErz.email).css  ].join(' ')}
                    required name="email"/>
                    <div className={fieldAlert.alert(formErz.email).css}>
                    {fieldAlert.alert(formErz.email).value}
                    </div>
                    </div>


                    <div className="col-md-3 mb-3">
                    <label >Mobile</label>
                    <input type="mobile" onChange={this.changeHandler} className={['form-control', borderAlert.alert(formErz.mobile).css  ].join(' ')}
                    required name="mobile"/>
                    <div className={fieldAlert.alert(formErz.mobile).css}>
                    {fieldAlert.alert(formErz.mobile).value}
                    </div>
                    </div>
                </div>



                <div className="form-row">


                    <div className="col-md-4 mb-3">
                    <label >Pay</label>
                        <select name="purpose"  onChange={this.changeHandler}  className={['custom-select', borderAlert.alert(formErz.purpose).css  ].join(' ')} 
                        id="validationServer04" required >
                            <option >Choose...</option>
                            <option>Building Fund</option>
                            <option>Couples Dinner</option>
                            <option>Discovery for men</option>
                            <option>Offering</option>
                            <option>Seed Offering</option>
                            <option>Tithe</option>
                        </select>
                        <div className={fieldAlert.alert(formErz.purpose).css}>
                        {fieldAlert.alert(formErz.purpose).value}
                       </div>
                    </div>

                    <div className="col-md-4 mb-3">
                    <label >Amount <small style={{color:'red'}}>(in dollars only)</small> </label>
                    <input type="text"  onChange={this.changeHandler} name="amount" className={['form-control', borderAlert.alert(formErz.amount).css  ].join(' ')}
                    id="validationServer05" required />
                     <div className={fieldAlert.alert(formErz.amount).css}>
                    {fieldAlert.alert(formErz.amount).value}
                    </div>
                    </div>
                </div>
           
                <button disabled={!fieldAlert.isformValid(formErz,this.state.myForm)}
                className="btn btn-primary" onClick={this.submitFormHandler} >
                Submit form
                </button>
                </form>
        );


        return( 
                 <Aux>
                 {theForm} 
                 <Pscard />
                 </Aux>
                
        )
    }

}

export default withRouter(ForeignForm);