
import React from 'react';
import Aux from '../../HOC/Aux';
import Nav from '../nav/nav';
import ForeignForm from '../../containers/ForeignForm/ForeignForm';


const foreign = () => {
    return ( 
        <Aux>
            <Nav/> 
               <ForeignForm />
        </Aux>
  

     );
}
 
export default foreign;