import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Spinners from '../../../Partials/Spinner/Spinner';
import Aux from '../../../HOC/Aux';
import Globalcard from '../../../Partials/paymentlogo/globalcard';
import * as konst from '../../../Helpers/Constants/Konst';



class localSummary  extends Component {


    state = {
        myForm:{
            fullname:  null,
            mobile:    null,
            email:     null,
            currency:  null,
            purpose:   null,
            amount:    null,


                formErrors:{
                    fullname: 'e',
                    mobile: 'e',
                    email:'e',
                    address:'e',    
                    currency:  'e',
                    purpose:   'e',
                    amount:    'e',
                }
        },
        loading:false,
        mref:null,
    
    }

    
    componentDidMount(){
        const {mystate,mref} = this.props.location;
      //  const currentState = this.state.myForm;
       // console.log(mystate,mref);
       //console.log(konst.MERCHANTID,konst.POSTURL);
        if(mystate){
         //   let newValues = currentState;
         //   newValues = mystate;
            this.setState({ myForm : mystate,mref:mref});
        }
        
    }


    generateNumber =()=>{
        return Math.floor(Math.random() * (100000000 - 10000 + 1) ) + 10000;
    }

    okay =()=>{

        return;
    }

    // submitFormHandler = ()=>{

    
        
    //  this.setState({loading:true});
    //  const myForm = this.state.myForm;

    
   
    //  const info = {
    //     names :          myForm.fullname,
    //     phone_number:    myForm.mobile,
    //     email_address:   myForm.email,
    //     currency:        "NGN",
    //     purpose:         myForm.purpose,
    //     amount:          myForm.amount,
    //     merch_txnref:    this.generateNumber(),
    //     merchantid:      merchantid
    //  }
    // //console.log(info);

    // const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
    // const source = CancelToken.source();
    //  axiosInstance.post('/local-payment',info,{
    //     cancelToken: source.token
    //  }).then(resp =>{
    //     //this.setState({loading:false});
    //     console.log(resp);
    //     //console.log(resp.config.url);
    //     //this.props.history.replace('/foreign-redirect');
    //  //window.location.href = resp.data;
    //  }).catch(e=> { 
    //      this.setState({loading:false}); 
    //      console.log(e)});

    // }




    render(){
        //const postUrl ="https://www.globalpay.com.ng/GlobalPayAPI/Paymentgatewaycapture.aspx";
        // const merchantid = 378; // live
        //const postDemoUrl ="https://demo.globalpay.com.ng/GlobalPayAPI/Paymentgatewaycapture.aspx";
        //const merchantid = 5580; // demo
       
        const {myForm,loading,mref}  = this.state;

        //console.log(myForm);
        
        const glopayform =(
            

            <form method="POST" action={konst.POSTURL} style={{display:'inline'}}>
            <input hidden onChange={this.okay} name="names"               value={myForm.fullname}  />
            <input hidden onChange={this.okay} name="phone_number"        value={myForm.mobile}  />
            <input hidden onChange={this.okay} name="email_address"       value={myForm.email}  />
            <input hidden onChange={this.okay} name="currency"            value="NGN"  />
            <input hidden onChange={this.okay} name="purpose"             value={myForm.purpose}  />
            <input hidden onChange={this.okay} name="amount"              value={myForm.amount}  />
            <input hidden onChange={this.okay} name="merch_txnref"        value={mref}  />
            <input hidden onChange={this.okay} name="merchantid"          value={konst.MERCHANTID}  />
            <button className="btn btn-success" type="submit" >Continue</button>
            </form>
        );

          
          let myTable = (
                    <div className="alert alert-info" role="alert">
                    <h4 className="alert-heading">No data available!</h4>
                    <p className="mb-0">
                    <Link to="/" className="btn btn-light mr-3">ANOTHER PAYMENT</Link>
                    <a href={konst.TFOLCHOME} className="btn btn-success">TFOLC HOME</a>
                    </p>
                 </div>
          )
            

          if(myForm.fullname !== null ){
            myTable = ( 
                <Aux>
                
                <table className="table table-sm table-borderless">
                <thead>
                    <tr>
                        <th scope="col" style={{textTransform:"uppercase"}}>{myForm.fullname } </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td >{myForm.mobile}</td>
                    </tr>
                    <tr>
                        <td >{myForm.email}</td>
                   </tr>
                   <tr>
                        <td >Payment for <strong>{myForm.purpose}</strong></td>
                    </tr>    
                   <tr>
                        <th ><span>&#8358;</span> {myForm.amount}</th>
                   </tr>              
            </tbody>
            </table>
            <div className="card-footer bg-transparent ">
                
               
                <Link to="/local-payment" className="btn btn-danger mr-3">Go Back</Link>
                 {myForm.fullname=== null ? null : glopayform }
            </div>
            </Aux>
            );






          }








        return (
            <Aux>
            <div className="card ">
            <div className="card-header text-white bg-danger">
                Transaction Summary
               </div>
               <div className="card-body">
            { loading ? <Spinners /> : myTable}
            </div> 
            </div> 
            <Globalcard/>
            </Aux>
          );

    


}



}
 
export default localSummary;