import React from 'react';
import Landing from './containers/Landing/Landing';
import {Route, Switch,Redirect} from 'react-router-dom';
import foreign from './components/foreign/foreign';
import local from './components/local/local';
import Header from './components/header/header';
import Aux from './HOC/Aux';
import Footer from './components/footer/footer';
import ForeignSummary from './components/foreign/ForeignSummary/ForeignSummary';
import Redirection  from './components/redirect/Redirect';
import localSummary from './components/local/localSummary/localSummary';

function App() {
  return (
             <Aux>
              <div className="container" style={{marginBottom:"80px"}}>
                <Header/>
                <Switch>
                  <Route exact path='/' component={Landing}/>
                  <Route path='/foreign-payment' component={foreign}/>
                  <Route path='/foreign-summary' component={ForeignSummary}/>
                  <Route path='/redirect' component={Redirection}/>
                  <Route path='/local-payment' component={local}/>
                  <Route path='/local-summary' component={localSummary}/>
                  <Redirect to='/' />
                </Switch>

              </div>
             <Footer />
            </Aux>
  );
}

export default App;
