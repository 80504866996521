import  React from 'react';
import Aux from '../../HOC/Aux';
import Logo from '../../Partials/Logo/Logo';

const header = () => {
    return ( 
        <Aux>
            <h2 className="text-center">
            <Logo classes="text-center" />
            </h2>         	

            <div className="alert alert-info text-center">
            <h2>Give Online</h2>
            <p><em>* Give, and it will be given to you. A good measure, pressed down, shaken together and running over, will be poured into your lap. For with the measure you use, it will be measured to you."</em></p>
            </div>
        </Aux> 
    );
}
 
export default header;