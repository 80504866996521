import React from 'react';
import gtbanklogo from "../../assets/images/gtb.png";
import gtcologo from "../../assets/images/gtco.png";
import stanbiclogo from "../../assets/images/stanbic.jpeg";
import zenithlogo from "../../assets/images/zenith.png";
import Aux from '../../HOC/Aux';

const accounts = () => {
    return ( 
        <Aux>
            <div className="col-sm-6 mb-4">
                <div className="card border-secondary">
                    <div className="card-header bg-transparent ">
                        <img width="50px" height="50px" src={gtbanklogo} alt=""/>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">GTBANK</h5>
                        <p className="card-text">
                             The Fountain of Life Church <br/>
                                 0006693445<br/>
                              <span className="badge badge-secondary">
                                Sort Code: 058152104
                                </span>
                        </p>
                     </div>
                </div>
            </div>


            <div className="col-sm-6 mb-4">
                <div className="card border-secondary">
                    <div className="card-header bg-transparent ">
                        <img width="50px" height="50px" src={gtcologo} alt=""/>
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">GTCO (ILUPEJU BRANCH) (£)</h5>
                        <p className="card-text">
                             The Fountain of Life Church <br/>
                             0006693885 (UK Account)<br/>
                              <span className="badge badge-secondary mr-2">
                                Sort Code: 058-152104
                                </span>
                              <span className="badge badge-secondary">
                                Swift Code: GTBINGLA
                                </span>
                        </p>
                     </div>
                </div>
            </div>


            <div className="col-sm-6 mb-4">
                <div className="card border-secondary">
                    <div className="card-header bg-transparent ">
                        <img width="50px" height="50px" src={zenithlogo} alt=""/>
                    </div>
                    <div className="card-body">
                    <h5 className="card-title">ZENITH BANK PLC</h5>
                        <p className="card-text"> The Fountain of Life Church <br/>1011287570<br/>
                            <span className="badge badge-secondary">
                            Sort Code: 057150123
                            </span>
                        </p>
                    </div>
                </div>
            </div>




            <div className="col-sm-6 mb-4">
                <div className="card border-secondary">
                    <div className="card-header bg-transparent ">
                        <img width="50px" height="50px" src={stanbiclogo} alt=""/>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">STANBIC IBTC BANK PLC</h5>
                            <p className="card-text">The Fountain of Life Church<br/>0011829260<br/>
                                <span className="badge badge-secondary">
                                Sort Code: 221151880
                                </span>
                            </p>
                    </div>
                </div>
            </div>
        </Aux>
    );
}
 
export default accounts;