import axios from 'axios';



const axiosInstance = axios.create({
    // baseURL : 'http://paymentapi.local/api/',
    baseURL : 'https://www.tfolc.org/payonlineapi/api/',
});
//axios.defaults.baseURL = 'https://jsonplaceholder.typicode.com';
// axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
 axios.defaults.headers.post['Content-Type'] ='application/json';
 axios.defaults.headers.post['Accept'] ='application/json';


export default axiosInstance;