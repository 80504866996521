import React from 'react';
//import styles from './Spinner.module.css';

//https://projects.lukehaas.me/css-loaders/

const Spinner = () => {




return (  
    <div className="progress">
    <div className="progress-bar progress-bar-striped progress-bar-animated" 
    role="progressbar" aria-valuenow="75" aria-valuemin="0"
     aria-valuemax="100" style={{width:"75%"}}></div>
   </div>
);

   // return (  <div className={styles.Loader}>Loading...</div>);
}
 
export default Spinner;