import React from 'react';
import Mc from '../../assets/images/1.png';
import Vv from '../../assets/images/2.png';
import It from '../../assets/images/3.png';
import et from '../../assets/images/4.jpg';


const globalcard = () => {
    return ( 
        <div className="col-12-md mt-5  img-fluid">
        <img src={Mc} alt="..." className="img-thumbnail"/>
        <img src={Vv} alt="..." className="img-thumbnail" />
        <img src={et} alt="..." className="img-thumbnail" />
        <img src={It} alt="..." className="img-thumbnail" />
        </div>
     );
}
 
export default globalcard;