import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Spinner from '../../Partials/Spinner/Spinner';
import Aux from '../../HOC/Aux';
import * as konst from '../../Helpers/Constants/Konst';

class Redirect extends Component {

    state ={
        status:null
    }

    componentDidMount(){
        const query = new URLSearchParams(this.props.location.search);

        for(let param of query.entries()){

            if(param[0] === 'status'){
                console.log(param[1]);// setting it into the object
                this.setState({status:param[1]});
            }
        }
    }

    render(){
          
             let colour = null;
             let body =null;
             switch(this.state.status){
                 case "success":
                 colour = "alert-success";
                 body ="Transaction Successful";
                 break;
                 case "fail":
                 colour = "alert-danger";
                 body ="Transaction failed";
                 break;
                 default:
                 colour = "alert-warning";
                 body ="Transaction pending"

             }


             let notice = <Spinner />

             if(this.state.status){
                 notice = (
                        <div className={['alert',colour].join(' ')} role="alert">
                            <h4 className="alert-heading">{body}</h4>
                             <hr/>
                             <p className="mb-0">
                            <Link to="/" className="btn btn-light mr-3">ANOTHER PAYMENT</Link>
                            <a href={konst.TFOLCHOME} className="btn btn-success">TFOLC HOME</a>
                             </p>
                        </div>
                 )
             }

        return ( 
                <Aux>
                 {notice}
                </Aux>
        );
    }

}
 
export default Redirect;